import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";

export const get_patients = createAsyncThunk(
  "get_patients_in_store",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/patients/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const createPatient = createAsyncThunk(
  "create_patient",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL_V2}/patients/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const getPatientDetails = createAsyncThunk(
  "get_patient_details",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/patients/${params.id}/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const getPatientShippingAddresses = createAsyncThunk(
  "get_patient_shiping_address",
  async (params, { rejectWithValue }) => {
    try {
      const { id, ...restparams } = params;
      const data = await axiosClient.get(
        `${config.API_URL}/patients/${id}/shipping_addresses/`,
        { restparams },
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const postPatientShippingAddress = createAsyncThunk(
  "post_patient_shiping_address",
  async (params, { rejectWithValue }) => {
    const { id, ...rest } = params;
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/patients/${params?.id}/shipping_addresses/`,
        rest,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const patchPatientShippingAddress = createAsyncThunk(
  "patch_patient_shiping_address",
  async (params, { rejectWithValue }) => {
    const { id, addressId, ...restparams } = params;
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/patients/${params?.id}/shipping_addresses/${addressId}/`,
        restparams,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const getPatients = createAsyncThunk(
  "get_patients",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/patients/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const postPatient = createAsyncThunk(
  "post_patient",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/patients/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);