import React, { useEffect, useRef, useState, useCallback } from "react";
import "./SingleProduct.scss";
import { theme, Layout, Image, Segmented, Skeleton, notification } from "antd";
import {
  SingleProductHeader,
  ProductSIze,
  RelatedProducts,
} from "./components";
import { useLocation, useNavigate, useParams } from "react-router";
import useWindowWidth from "../../hooks/useWindowWidth";
import { getMenuItem } from "../../features/menuItems/menuItemsAction";
import { resetMenuItemsError } from "../../features/menuItems/menuItemsSlice";
import { useDispatch, useSelector } from "react-redux";
import product_logo from "../../assets/logo_product.svg";
import { getRelatedProducts } from "../../features/relatedProducts/relatedProductsAction";
import Utils from "../../utils";

const { Content } = Layout;

export default function SingleProduct() {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const { isMobile, isMiddlesDesktop } = useWindowWidth();
  const location = useLocation();

  const { productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const { loading, singleProduct, error } = useSelector(
    (state) => state?.menuItems
  );

  const singleProductHeaderRef = useRef(null);
  const segements = [
    {
      label: "Product Description",
      value: "product_description",
    },
    // {
    //   label: "Review",
    //   value: "review",
    // },
  ];
  const [selectedSegment, setSelectedSegment] = useState(segements[0]?.value);

  const getContent = (segment) => {
    switch (segment) {
      case "product_description":
        return (
          <div
            className="singleProduct-cont__additionalInfo__middleText"
            style={{
              color: token?.Typography?.mainColorText,
              fontFamily: token?.fontFamily,
            }}
            dangerouslySetInnerHTML={
              loading
                ? {
                    __html: "",
                  }
                : {
                    __html: singleProduct?.description
                      ? singleProduct?.description
                      : "No description",
                  }
            }
          />
        );
      case "review":
        // return (
        //   <div className="singleProduct-cont__additionalInfo__reviewWrapper">
        //     <div className="singleProduct-cont__additionalInfo__reviewWrapper__addComment">
        //       <TextArea
        //         className="singleProduct-cont__additionalInfo__reviewWrapper__addComment__textArea"
        //         placeholder="Type your comment"
        //         autoSize={{ minRows: 2, maxRows: 4 }}
        //       />
        //       <Button
        //         style={{
        //           width: "100%",
        //           maxWidth: "129px",
        //         }}>
        //         Add Comment
        //       </Button>
        //     </div>
        //     <Divider
        //       type="horizontal"
        //       className="singleProduct-cont__additionalInfo__reviewWrapper__addComment__divider"
        //       style={{
        //         backgroundColor: token?.Typography?.mainColorText,
        //       }}
        //     />
        //     <div className="singleProduct-cont__additionalInfo__reviewWrapper__commentCont">
        //       <Typography.Text
        //         className="singleProduct-cont__additionalInfo__title"
        //         style={{
        //           color: token.Typography?.mainColorText,
        //         }}>
        //         Comment Title
        //       </Typography.Text>
        //       <div className="singleProduct-cont__additionalInfo__reviewWrapper__commentCont__rateCont">
        //         <div className="singleProduct-cont__additionalInfo__reviewWrapper__commentCont__rateCont__rate">
        //           <Rate
        //             allowHalf
        //             value={reviewRate}
        //             character={<StarIcon width={15} height={15} />}
        //             count={5}
        //             onChange={(val) => {
        //               setReviewRate(val);
        //             }}
        //             style={{
        //               fontSize: "12px",
        //             }}
        //           />
        //           <Typography.Text className="singleProduct-cont__additionalInfo__rateText">
        //             {reviewRate}
        //           </Typography.Text>
        //         </div>
        //         <div className="singleProduct-cont__additionalInfo__reviewWrapper__commentCont__rateCont__likeCont">
        //           <div className="singleProduct-cont__additionalInfo__reviewWrapper__commentCont__rateCont__likeCont__iconWrapper">
        //             <Button
        //               size="small"
        //               type="text"
        //               icon={
        //                 <LikeOutlined
        //                   style={{
        //                     fontSize: "18px",
        //                     color: token?.icons?.whiteIcon,
        //                   }}
        //                 />
        //               }
        //             />
        //             <Typography.Text className="singleProduct-cont__additionalInfo__rateText">
        //               9
        //             </Typography.Text>
        //           </div>
        //           <div className="singleProduct-cont__additionalInfo__reviewWrapper__commentCont__rateCont__likeCont__iconWrapper">
        //             <Button
        //               size="small"
        //               type="text"
        //               icon={
        //                 <DislikeOutlined
        //                   style={{
        //                     fontSize: "18px",
        //                     color: token?.icons?.whiteIcon,
        //                   }}
        //                 />
        //               }
        //             />
        //             <Typography.Text className="singleProduct-cont__additionalInfo__rateText">
        //               9
        //             </Typography.Text>
        //           </div>
        //         </div>
        //       </div>
        //       <Typography.Text
        //         className="singleProduct-cont__additionalInfo__middleText"
        //         style={{
        //           color: token.Typography?.mainColorText,
        //         }}>
        //         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        //         eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
        //         enim ad minim veniam, quis nostrud exercitation ullamco laboris
        //         nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
        //         in reprehenderit in voluptate velit esse cillum dolore eu fugiat
        //         nulla pariatur. Excepteur sint occaecat cupidatat non proident,
        //         sunt in culpa qui officia deserunt mollit anim id est laborum.
        //       </Typography.Text>
        //       <Typography.Text className="singleProduct-cont__additionalInfo__smallText">
        //         Reviewer · today
        //       </Typography.Text>
        //     </div>
        //   </div>
        // );
        return "";
      default:
        return "";
    }
  };
  useEffect(() => {
    const parentCategory = Utils?.findParentCategory(location?.state);
    dispatch(getMenuItem({ id: productId }));
    dispatch(
      getRelatedProducts({
        page: 1,
        per_page: 25,
        category: parentCategory ? parentCategory?.name : undefined,
        count__gt: 0,
      })
    );
  }, [dispatch, productId, isAuthenticated, location?.state]);

  useEffect(() => {
    if (location?.pathname?.includes("product")) {
      if (error && !loading) {
        for (let key in error) {
          openNotificationWithIcon("error", key, error[key]);
        }
        dispatch(resetMenuItemsError());
      }
    }
  }, [error, loading, location?.pathname, openNotificationWithIcon, dispatch]);

  return (
    <>
      {contextHolder}
      <Content
        className="singleProduct-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <SingleProductHeader
          token={token}
          singleProductHeaderRef={singleProductHeaderRef}
          loading={loading}
          product={singleProduct}
          navigate={() => {
            navigate(-1);
          }}
        />
        <div className="singleProduct-cont__detailsWrapper">
          <div
            className="singleProduct-cont__detailsWrapper__images"
            style={
              !singleProduct?.picture_url && !loading
                ? {
                    background: token?.product_default_image_bg,
                    borderRadius: "8px",
                  }
                : null
            }>
            {loading ? (
              <Skeleton.Image
                active={true}
                style={{
                  width: "100%",
                  height: isMobile
                    ? "370px"
                    : isMiddlesDesktop
                    ? "430px"
                    : "569px",
                  backgroundColor: token?.Card?.colorBgCard,
                  borderRadius: "8px",
                }}
              />
            ) : (
              <Image
                alt="product_big_image"
                src={
                  singleProduct?.picture_url
                    ? singleProduct?.picture_url
                    : product_logo
                }
                width="100%"
                height={
                  isMobile ? "370px" : isMiddlesDesktop ? "430px" : "569px"
                }
                style={
                  !singleProduct?.picture_url
                    ? {
                        width: "100px",
                        borderRadius: "8px",
                      }
                    : {
                        borderRadius: "8px",
                      }
                }
              />
            )}
          </div>
          <ProductSIze
            token={token}
            product={singleProduct}
            loading={loading}
          />
        </div>
        <div className="singleProduct-cont__additionalInfo">
          <div>
            <Segmented
              disabled={loading}
              options={segements}
              value={selectedSegment}
              onChange={(val) => {
                setSelectedSegment(val);
              }}
            />
          </div>
          {getContent(selectedSegment)}
        </div>
        <RelatedProducts
          token={token}
          singleProductHeaderRef={singleProductHeaderRef}
        />
      </Content>
    </>
  );
}
