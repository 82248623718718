import React, { useEffect, useState } from "react";
import "./CreateOrder.scss";
import { Typography } from "antd";
import CashierCard from "../CashierCart/CashierCard";
import CashierCheckout from "../CashierCheckout/CashierCheckout";
import { useDispatch, useSelector } from "react-redux";
import { calculateOrder } from "../../../../features/order/orderActions";

export default function CreateOrder({
  token,
  isMobile,
  width,
  openNotificationWithIcon,
}) {
  const { items } = useSelector((state) => state?.card);
  const dispatch = useDispatch();
  const [orderCalculation, setOrderCalculation] = useState({});
  const [selectedPromoCode, setSelectedPromoCode] = useState(undefined);

  useEffect(() => {
    if (items?.length) {
      dispatch(
        calculateOrder({
          order_items: items?.map((item) => {
            return {
              variant_id: item?.variant?.id,
              quantity: item?.quantity,
            };
          }),
          promo_code: selectedPromoCode ? selectedPromoCode?.value : undefined,
        })
      ).then((data) => {
        if (data?.type === "calculateOrder/fulfilled") {
          if (data?.payload) {
            setOrderCalculation(data?.payload);
          }
        }
      });
    } else {
      setOrderCalculation({});
    }
  }, [items, dispatch, selectedPromoCode]);

  return (
    <div className="createOrder-cont">
      <Typography.Text
        className="createOrder-cont__text"
        style={{
          color: token?.Typography?.mainColorText,
        }}>
        Cart
      </Typography.Text>
      <CashierCard token={token} />
      <CashierCheckout
        token={token}
        isMobile={isMobile}
        width={width}
        selectedPromoCode={selectedPromoCode}
        setSelectedPromoCode={setSelectedPromoCode}
        orderCalculation={orderCalculation}
        setOrderCalculation={setOrderCalculation}
        openNotificationWithIcon={openNotificationWithIcon}
      />
    </div>
  );
}
